.m-header {
  position: absolute;
  top: 150px;
  width: 100%;
  transition: top 0.8s, transform 0.8s, opacity 1s 0.5s, background-color 0.8s;
  z-index: 101;

  @include mediaQueries("M", "min") {
    top: 50%;
    transform: translateY(-50%);
  }

  .is--1st-in & {
    @include mediaQueries("M", "min") {
      top: 50%;
      transform: translateY(-50%);
    }
    @media (min-width: 1366px) and (max-height: 799px) {
      &__logo {
        width: 300px;
        height: 98px;
      }
      &__title {
        margin-top: 2rem;
        font-size: 40px;
      }
      .c-button {
        margin-block: 1rem;
      }
    }
  }

  .is--2nd-in & {
    @include mediaQueries("M", "max") {
      background-color: #000;
      z-index: 500;
    }
  }

  .is--1st-out &,
  .is--3rd-out & {
    top: 0;
    @include mediaQueries("M", "min") {
      transform: translateY(0);
    }
    .m-header__logo {
      transform: scale(0.6);
    }
  }
  .is--3rd-out & {
    opacity: 1;
    transition: opacity 0.3s 1s;
  }
  .is--3rd-in & {
    @include mediaQueries("ML", "max") {
      top: 0;
      transform: translateY(0);
      .m-header__logo {
        transform: scale(0.6);
      }
    }
  }

  .l-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    display: block;
    width: 256px;
    height: 84px;
    opacity: 0;
    transition: transform 0.8s, opacity 1.5s 1s;
    @include mediaQueries("M", "min") {
      width: 390px;
      height: 128px;
    }

    .is--1st-in &,
    .is--1st-out &,
    .is--2nd-in & {
      opacity: 1;
    }

    .is--3rd-in & {
      opacity: 1;
      @include mediaQueries("ML", "min") {
        opacity: 0;
      }
    }
  }

  &__content {
    position: relative;
    margin: 0 auto;
    max-width: 300px;
    text-align: center;
    z-index: 10;
    opacity: 0;
    transition: opacity 1s;

    @include mediaQueries("ML", "min") {
      position: relative;
      z-index: 5;
      max-width: 500px;
      min-width: 30rem;
    }

    .is--1st-out & {
      opacity: 0;
      overflow: hidden;
      @include mediaQueries("ML", "max") {
        height: 0;
      }
    }

    .is--1st-in & {
      opacity: 1;
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: 27px;
    line-height: 54px;
    font-weight: 400;
    margin: 0 auto;
    @include mediaQueries("ML", "min") {
      margin: 3rem auto 2rem;
      font-size: 48px;
      line-height: normal;
      letter-spacing: 4.8px;
    }
    span {
      display: inline-block;
      opacity: 0;
      filter: blur(4px);

      @include mediaQueries("ML", "min") {
        display: inline;
        font-size: 48px;
      }

      &.colored {
        color: #c8006d;
        @include mediaQueries("ML", "max") {
          font-size: 64px;
        }
      }
      &.mobile-break {
        @include mediaQueries("ML", "max") {
          display: block;
          height: 0;
        }
      }

      @include generate-fade-in-animation(38, 0.03s);
    }
  }

  &__subtitle {
    font-size: 13px;
    line-height: 2;
    margin: 0 auto 2rem;
    opacity: 0;
    transition: opacity 1.5s 1.5s;
    @include mediaQueries("ML", "min") {
      font-size: 18px;
    }

    .is--1st-in & {
      opacity: 1;
    }
  }

  .c-button {
    margin: 2rem auto;
    opacity: 0;
    transition: opacity 1.5s 2s, box-shadow 0.2s, text-shadow 0.2s;
    .is--1st-in & {
      opacity: 1;
    }
  }
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}
