.m-footer {
  position: relative;
  @include mediaQueries("M", "max") {
    display: none;
    padding-bottom: 88px;
  }
  .is--3rd-in & {
    @include mediaQueries("M", "min") {
      padding-bottom: 88px;
    }
    @include mediaQueries("ML", "min") {
      padding-bottom: 0;
    }
  }
  width: 100%;
  padding-inline: 40px;
  opacity: 0;
  transition: opacity 1s 1s;
  z-index: 100;

  @include mediaQueries("ML", "min") {
    position: absolute;
    bottom: 0;
  }

  .is--2nd-in &,
  .is--3rd-in & {
    @include mediaQueries("ML", "min") {
      z-index: 500;
    }
  }

  .is--1st-out &,
  .is--3rd-in &,
  .is--2nd-in & {
    display: block;
    opacity: 1;
  }

  .is--1st-in & {
    z-index: 1;
  }

  &__list {
    display: flex;
    gap: 10px 20px;
    padding: 2rem 0;
    @include mediaQueries("M", "max") {
      flex-direction: column;
    }
  }

  &__listEl {
    color: rgba($color--white, 0.4);
    font-size: 13px;
    line-height: 2.1rem;
  }
}
