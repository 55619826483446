// ==========================================================================
// = Variables
// ==========================================================================

@use "sass:math";

// ==================================================================
// = Breakpoints
// ==================================================================

// 480px / 720px / content(1180px) / 1280px / 1440px /
$breakpoints: (
  // XS: 320px,
  S: 360px,
  M: 768px,
  ML: 1024px,
  L: 1280px,
  XL: 1600px,
  XXL: 1920px
) !default;

// ==========================================================================
// = Fonts
// ==========================================================================

$font-size--default: 16px;
$font-size--base: math.div(10 * 100%, 16);

$font-weight--light: 300;
$font-weight--regular: 400;
$font-weight--bold: 700;

$font-family--primary: "isonormregular", sans-serif;

// ==========================================================================
// = Colors
// ==========================================================================

// Fundamental colors
$color--black: #000;
$color--white: #fff;

$color--purple: #303e9f;
$color--orange: #ffaa00;
$color--green: #1eaf70;
$color-red: #e04f5f;
$color--grey: #00000014;

// ...

// ...
