.c-input {
    background-color: $color--white;
    color: $color--purple;
    font-size: 1.8rem;
    font-weight: $font-weight--light;
    border-radius: 10rem;
    padding: 1.6rem 4rem;
    text-align: center;

    &:disabled {
        opacity: .8;
        cursor: not-allowed;
    }
    
}
