.m-overlayer {
  position: fixed;
  inset: 0;
  display: none;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
  transition: opacity 0.2s ease-in-out;

  &.is--active {
    display: flex;
  }

  &__content {
    display: flex;
    flex-direction: column;
    max-width: calc(100vw - 50px);
    row-gap: 30px;
    position: relative;
    padding: 25px;
    max-height: calc(100vh - 6rem);
    overflow-y: auto;
    color: #000;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.16);

    @include mediaQueries("M", "min") {
      padding: 50px;
      max-width: 75vw;
    }

    @include mediaQueries("XXL", "min") {
      padding: 56px 80px;
      max-width: 1000px;
    }

    h2 {
      font-size: 32px;
      line-height: 1.2;
      @include mediaQueries("M", "max") {
        padding-right: 40px;
      }
    }
  }

  &__close {
    position: absolute;
    top: 0;
    right: 0;
    width: 56px;
    font-size: 24px;
    background-color: #fff;
    overflow: hidden;
    @include mediaQueries("XXL", "min") {
      top: 16px;
      right: 16px;
    }

    &:before {
      content: "";
      display: block;
      width: 56px;
      height: 56px;
      background: url("../img/close.svg") no-repeat center;
      transition: transform 0.3s ease-out;
    }

    @media (hover) {
      &:hover {
        &:before {
          transform: rotate(180deg);
        }
      }
    }
  }
}
