.c-poster {
  position: relative;
  width: 96px;
  aspect-ratio: 0.67;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow: hidden;

  @include mediaQueries("ML", "min") {
    width: 120px;
  }

  @at-root {
    label#{&} {
      margin-bottom: 6px;
      font-size: 10px;
      cursor: pointer;
    }
  }

  &__label {
    position: absolute;
    top: 0;
    left: 0;
    width: 57px;
    height: 25px;
    padding-left: 5px;
    font-size: 20px;
    line-height: 1.3;
    z-index: 1;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: -5px;
      display: block;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, #c8006d, #7a0042);
      transform: skew(-20deg);
      z-index: -1;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked ~ .checkmark {
      top: 0;
      svg path {
        &:first-of-type {
          animation: dash 0.2s linear forwards 0.1s;
        }
        &:last-of-type {
          opacity: 1;
        }
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: linear-gradient(
      90deg,
      rgba(200, 1, 109, 0.7) 0%,
      rgba(123, 1, 67, 0.7) 110%
    );
    transition: top 0.3s;

    svg path {
      &:last-of-type {
        opacity: 0;
      }
      &:first-of-type {
        stroke-dasharray: 30;
        stroke-dashoffset: 30;
      }
    }
  }

  @media (hover) {
    &:hover input ~ .checkmark {
      top: 0;
    }
  }

  &.lazy {
    background-image: none;
  }
}

@include generate-poster-classes(1998, 2022, 1, 9);

@keyframes dash {
  from {
    stroke-dashoffset: 30;
  }
  to {
    stroke-dashoffset: 0;
  }
}
