// ==================================================================
// = Layout
// ==================================================================

// Layout gutter
$layout__gutter: 8px;

// Widths
$content__width--mobile: 80 * $layout__gutter;
$content__width--desktop: 140 * $layout__gutter;

// module main paddings
// $content__gutter--1: (
//   phone: 40px,
//   desktop: 80px,
// );

$container__gutter--horizontal: (
  phone: 3 * $layout__gutter,
  desktop: 5 * $layout__gutter,
);

$container__gutter--vertical: (
  phone: 3 * $layout__gutter,
  desktop: 5 * $layout__gutter,
);

@mixin container__horizontal-gutter() {
  @include fit-for-all-screens("padding-left", $container__gutter--horizontal);
  @include fit-for-all-screens("padding-right", $container__gutter--horizontal);
}

@mixin container__vertical-gutter() {
  @include fit-for-all-screens("padding-top", $container__gutter--vertical);
  @include fit-for-all-screens("padding-bottom", $container__gutter--vertical);
}

@mixin wrapper() {
  width: 100%;
  @include container__horizontal-gutter();
  margin: 0 auto;
  max-width: $content__width--mobile;
  @include mediaQueries("ML", "min") {
    max-width: $content__width--desktop;
  }
}

@mixin section() {
  @include container__vertical-gutter();

  @include mediaQueries("ML", "min") {
    // min-height: 80rem;
    // height: 100vh;
  }
}

.l-wrapper {
  @include wrapper();
}
