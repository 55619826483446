.m-movies {
  .is--1st-in & {
    display: none;
  }
  position: relative;
  top: 0;
  display: grid;
  left: 110%;
  width: 100%;
  height: 0;
  transition: left 1s 0.8s;
  z-index: 300;

  @include mediaQueries("ML", "min") {
    grid-template-columns: 40% 60%;
  }
  @include mediaQueries("L", "min") {
    grid-template-columns: repeat(2, 1fr);
  }

  .is--3rd-in & {
    display: grid;
    left: 0;
    height: 100%;
  }
  .is--3rd-out & {
    left: 110%;
    height: 0;
    transition-delay: 0s;
  }

  .is--3rd-in &,
  .is--3rd-out & {
    padding: 20px;
    @include mediaQueries("ML", "min") {
      padding: 100px 20px;
    }
  }

  .is--3rd-in.is--2nd-out & {
    .first-time {
      @include mediaQueries("ML", "max") {
        display: none;
      }
    }
    .second-time {
      opacity: 1;
      z-index: 1;
    }
  }

  .is--3rd-in:not(.is--2nd-out) & {
    .first-time {
      opacity: 1;
      z-index: 1;
    }
    .second-time {
      @include mediaQueries("ML", "max") {
        display: none;
      }
    }
  }

  &__container {
    position: relative;
    height: 100%;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    @include mediaQueries("ML", "min") {
      row-gap: 40px;
    }

    &.first-time,
    &.second-time {
      opacity: 0;
      @include mediaQueries("ML", "min") {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        transition: opacity 1s 1s;
        z-index: 0;
      }
    }
  }

  &__list {
    display: flex;
    max-width: 701px;
    gap: 14px;
    margin: 50px auto 0;

    @include mediaQueries("ML", "min") {
      margin-bottom: 50px;
    }

    .is--3rd-in & {
      flex-wrap: wrap;
      justify-content: center;
    }

    .c-poster {
      opacity: 0.2;
      flex: 0 0 90px;
      @include mediaQueries("L", "min") {
        flex: 0 0 100px;
      }
      @include mediaQueries("L", "min") {
        flex: 0 0 108px;
      }
      .is--3rd-in & {
        @include fade-in-elements(25, 0.1s, 1.2s);
      }
    }

    @include mediaQueries("ML", "min") {
      margin: 0;
    }
  }

  &__logo {
    width: 202px;
    height: 66px;
    margin: 0 auto;
    opacity: 0;
    transition: opacity 1s 1.5s;

    @include mediaQueries("ML", "min") {
      .is--3rd-in & {
        opacity: 1;
      }
    }
    @include mediaQueries("L", "min") {
      width: 390px;
      height: 128px;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    max-width: 400px;
    margin: 0 auto;
    text-align: center;

    width: 100%;
  }

  &__title {
    text-transform: uppercase;
    font-size: 50px;
    line-height: normal;
    font-weight: 400;
    opacity: 0;
    transition: opacity 1s 2s;

    .is--3rd-in & {
      opacity: 1;
    }

    @include mediaQueries("L", "min") {
      font-size: 90px;
      letter-spacing: 9px;
    }
  }

  &__subtitle {
    opacity: 0;
    transition: opacity 1s 2.5s;

    .is--3rd-in & {
      opacity: 1;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    transition: bottom 0.8s;

    @include mediaQueries("ML", "min") {
      .c-button {
        opacity: 0;
        transition: opacity 1s 3s, box-shadow 0.2s, text-shadow 0.2s;
        &:nth-of-type(2) {
          transition-delay: 3.25s, box-shadow 0.2s, text-shadow 0.2s;
        }
        .is--3rd-in & {
          opacity: 1;
        }
      }
    }
    @include mediaQueries("M", "max") {
      .c-button {
        padding-inline: 18px;
        font-size: 14px;
        span {
          display: none;
        }
      }
    }
    @include mediaQueries("ML", "max") {
      .is--3rd-in & {
        bottom: 0;
        transition-delay: 1s;
      }
      position: fixed;
      left: 0;
      bottom: -88px;
      flex-direction: row;
      justify-content: center;
      gap: 10px;
      width: 100%;
      padding: 20px;
      background-color: #000;
      z-index: 10;
    }
  }
}
