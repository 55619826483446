.c-button {
  display: inline-block;
  border: none;
  border-right: 3px solid #7a0042;
  border-left: 3px solid #c8006d;
  outline: none;
  padding: 1.6rem 4rem;
  margin-bottom: 0;
  position: relative;
  box-sizing: border-box;
  text-transform: uppercase;
  color: #fff;
  background: transparent;
  font-family: isonormregular;
  font-size: 18px;
  line-height: 1.1;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 0.2em;
  cursor: pointer;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  text-align: center;
  box-shadow: none;
  -webkit-box-shadow: none;
  transition: color 0.2s, box-shadow 0.3s, text-shadow 0.3s, opacity 0.3s;
  letter-spacing: 4.2px;
  @include mediaQueries("ML", "min") {
    font-size: 21px;
  }

  &:after,
  &:before {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    left: 0;
    height: 3px;
    background: linear-gradient(90deg, #c8006d, #a60059 48%, #7a0042);
  }

  &:before {
    top: 0;
  }

  &:after {
    bottom: 0;
  }

  @media (hover) {
    &:hover {
      text-decoration: none;
      box-shadow: 0 0 5px #c8006d, 0 0 15px #c8006d, 0 0 30px #c8006d;
      text-shadow: 0 0 5px #fff;
    }
  }

  &--bg {
    border: none;
    border-radius: 4px;
    &:after,
    &:before {
      display: none;
    }
    &,
    &:hover,
    &:focus {
      background: linear-gradient(90deg, #c8006d, #a60059 48%, #7a0042);
    }
  }

  &--big {
    padding: 18px 47px;
  }

  &.is--hide {
    display: none;
  }

  &.is--disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
}
