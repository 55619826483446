// ==========================================================================
// = Mixins
// ==========================================================================

@use "variables" as *;

// Media Queries
@mixin mediaQueries($breakpoint, $sizeType) {
  @if map-has-key($breakpoints, $breakpoint) {
    @if ($sizeType== "min") {
      @media screen and (min-width: map-get($breakpoints, $breakpoint)) {
        @content;
      }
    } @else if ($sizeType== "max") {
      @media screen and (max-width: map-get($breakpoints, $breakpoint) - 1px) {
        @content;
      }
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        +"Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin mediaQueriesRange($breakpointMin, $breakpointMax) {
  @if (
    map-has-key($breakpoints, $breakpointMin) and
      map-has-key($breakpoints, $breakpointMax)
  ) {
    @media screen and (min-width: map-get($breakpoints, $breakpointMin)) and (max-width: map-get($breakpoints, $breakpointMax) - 1px) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpointMin}` or `#{$breakpointMax}`. "
      +"Please make sure it is defined in `$breakpoints` map.";
  }
}

@mixin generate-poster-classes(
  $start-year,
  $end-year,
  $start-number,
  $end-number
) {
  @for $year from $start-year through $end-year {
    @for $poster from $start-number through $end-number {
      .c-poster--#{$year}-#{$poster} {
        background-image: url("../img/posters/#{$year}/#{$poster}.webp");
      }
    }
  }
}

// Maps a scss map variable to three different breakpoints
@mixin fit-for-all-screens($property, $map) {
  // mobile first approach
  #{$property}: map-get($map, "phone");

  @include mediaQueries("L", "min") {
    #{$property}: map-get($map, "desktop");
  }
}

// Only display content to screen readers
// See: https://a11yproject.com/posts/how-to-hide-content/
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/
@mixin sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  // /* for Internet Explorer */
  // clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
  border: 0;
}

@mixin hover_underline() {
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 100% 1px;
  transition: background-size 0.3s;

  @media (hover: hover) {
    &:hover {
      text-decoration: none;
      background-size: 0% 1px;
    }
  }
}

@mixin from-right-elements($count: 9, $delay: 0.1s, $major-delay: 0s) {
  @for $i from 1 through $count {
    &:nth-child(#{$i}) {
      animation: from-right
        #{$count *
        $delay}
        ease-out
        #{$delay *
        $i +
        $major-delay}
        both;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes from-right {
  from {
    opacity: 0.2;
    right: -100vw;
  }
  to {
    opacity: 1;
    right: 0;
  }
}
@mixin fade-in-elements($count: 9, $delay: 0.1s, $major-delay: 0s) {
  @for $i from 1 through $count {
    &:nth-child(#{$i}) {
      animation: fade-in 0.3s ease-in #{$delay * $i + $major-delay} both;
      animation-fill-mode: forwards;
    }
  }
}

@keyframes fade-in {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
}

@mixin generate-fade-in-animation($count: 5, $delay: 0.1s) {
  @for $i from 1 through $count {
    &:nth-child(#{$i}) {
      animation: fade-in
        0.8s
        (0.3s + ($delay * $i))
        forwards
        cubic-bezier(0.11, 0, 0.5, 0);
    }
  }
}
