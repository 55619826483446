// plugins
@import "swiper/swiper";
@import "swiper/modules/pagination";
@import "swiper/modules/parallax";
@import "swiper/modules/navigation";

//utils
@import "../scss/utils/fonts";
@import "../scss/utils/mixins";
@import "../scss/utils/variables";
@import "../scss/utils/reset";
@import "../scss/utils/layout";

//global
@import "base";

//components
@import "../components/button/index";
@import "../components/input/index";
@import "../components/poster/index";
@import "../components/radio/index";

// modules
@import "../modules/banners/index";
@import "../modules/footer/index";
@import "../modules/header/index";
@import "../modules/movies/index";
@import "../modules/overlayer/index";
@import "../modules/swiper/index";
