.m-swiper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  left: 100%;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transition: left 1s 0.8s;
  z-index: 200;

  @include mediaQueries("ML", "min") {
    padding-inline: 50px;
  }

  .swiper-thumbs {
    height: 35px;
    @include mediaQueries("M", "min") {
      height: 43px;
    }
    @include mediaQueries("ML", "min") {
      height: 80px;
    }
    @include mediaQueries("XL", "min") {
      height: 108px;
    }
    .swiper-slide {
      width: 100px;
      font-size: 36px;
      line-height: 1;
      opacity: 0.2;
      transition: opacity 0.3s;
      @include mediaQueries("ML", "min") {
        width: 180px;
        font-size: 60px;
      }
      @include mediaQueries("XL", "min") {
        width: 240px;
        font-size: 90px;
      }

      &.swiper-slide-active {
        opacity: 1;
      }
    }
  }

  .is--2nd-in & {
    left: 0;
    width: 100%;
    padding: 100px 28px 0;
    @include mediaQueries("M", "min") {
      padding-top: 140px;
    }
    @include mediaQueries("XL", "min") {
      padding-top: 180px;
    }
    @include mediaQueries("XXL", "min") {
      padding-top: 250px;
    }
  }
  .is--2nd-out & {
    left: -100%;
    padding: 100px 28px;
    transition: left 1s;
    @include mediaQueries("M", "min") {
      padding-block: 140px;
    }
    @include mediaQueries("XL", "min") {
      padding-top: 180px;
    }
    @include mediaQueries("XXL", "min") {
      padding-top: 250px;
    }
  }

  &__title {
    margin: 0px 20px 16px;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: 4.2px;
    text-align: center;
    text-transform: uppercase;
    @include mediaQueries("M", "min") {
      margin-bottom: 50px;
      font-size: 21px;
    }
  }

  .swiper {
    width: 100%;
    height: 100%;
    @include mediaQueries("ML", "min") {
      height: 360px;
      max-width: 1250px;
    }
    @include mediaQueries("XL", "min") {
      height: 400px;
    }
    @include mediaQueries("XXL", "min") {
      max-width: 1600px;
    }

    .swiper-slide {
      display: grid;
      grid-template-columns: repeat(3, 96px);
      grid-template-rows: auto;
      gap: 10px;

      @include mediaQueries("M", "min") {
        column-gap: 18px;
      }
      @include mediaQueries("ML", "min") {
        display: grid;
        grid-template-columns: repeat(9, 1fr);
        justify-items: center;

        .c-poster {
          width: 100%;
        }
      }

      .c-radio {
        opacity: 0.2;
      }
      .is--2nd-in & {
        &.swiper-slide-active {
          .c-radio {
            opacity: 0.2;
          }
          &:not(.is--active) {
            &:first-child {
              .c-radio {
                position: relative;
                right: -100vw;
                opacity: 0.2;
                @include from-right-elements(9, 0.1s, 0.8s);
              }
            }
          }
        }
        &.is--active {
          .c-radio {
            opacity: 1;
          }
        }
      }
    }

    &-navigation {
      position: absolute;
      bottom: -88px;
      align-items: center;
      justify-content: center;
      gap: 20px;
      width: 100%;
      transition: bottom 0.8s;
      z-index: 10;

      @include mediaQueries("M", "min") {
        width: calc(100% - 56px);
      }
      @include mediaQueries("ML", "min") {
        position: relative;
        bottom: 100px;
      }
      @include mediaQueries("XXL", "min") {
        bottom: 50px;
      }

      .is--2nd-in & {
        display: flex;
        @include mediaQueries("M", "max") {
          position: fixed;
          left: 0;
          bottom: 0;
          // top: calc(100svh - 88px);
          padding-block: 20px;
          background-color: #000;
        }
      }
      .is--2nd-out & {
        display: flex;
      }

      .c-button {
        position: relative;
        left: auto;
        right: auto;
        top: 0;
        width: 153px;
        height: auto;
        margin-top: 0;

        @include mediaQueries("M", "min") {
          width: 240px;
        }
        @include mediaQueries("M", "max") {
          padding-inline: 20px;
          font-size: 14px;
        }
      }
    }
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
  }
}
