html {
  font-size: $font-size--base;

  // The following lines are used to determine via JavaScript the current
  // viewport width
  font-family: "phone";

  @include mediaQueries("ML", "min") {
    font-family: "desktop";
  }

  /**
    * Smooth scrolling on the whole document
    */
  scroll-behavior: smooth;

  // global scale

  @media screen and (max-width: 374px) {
    font-size: $font-size--base * 0.75;
  }
  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    font-size: $font-size--base * 0.75;
  }
  @media screen and (min-width: 1921px) {
    font-size: $font-size--base * 1.25;
  }
}

body {
  position: relative;
  min-height: 100vh;
  margin: 0;
  background-color: #000;
  color: $color--white;
  font-family: $font-family--primary;
  font-weight: $font-weight--regular;
  font-size: 20px;
  line-height: 2.4rem;
  scroll-behavior: smooth;
  text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include mediaQueries("M", "max") {
    width: 100vw;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

main {
  position: relative;
  min-height: calc(100vh - 88px);
  height: 100%;
  overflow-x: hidden;

  @include mediaQueries("M", "min") {
    min-height: 100vh;
  }

  .is--1st-in & {
    @include mediaQueries("ML", "min") {
      overflow-y: hidden;
    }
  }

  .is--1st-out &,
  .is--3rd-in &,
  .is--3rd-out & {
    @include mediaQueriesRange("M", "L") {
      min-height: calc(100vh - 88px);
    }
  }
}

* {
  box-sizing: border-box;
  &:empty {
    margin: 0;
    padding: 0;
  }
}
*:before,
*:after {
  box-sizing: inherit;
}

ul {
  list-style: none;
  margin: 0;
  padding-left: 0;
}

button {
  color: inherit;
  background-color: transparent;
  cursor: pointer;
}

ol li {
  margin-left: 1em;
}

dl,
dt,
dd {
  margin: 0;
}

b,
strong {
  font-weight: $font-weight--bold;
}

small {
  line-height: 1;
}

img {
  vertical-align: middle;
  width: 100%;
}

[hidden] {
  display: none !important;
}

[disabled] {
  cursor: not-allowed;
}

.sr-only {
  @include sr-only;
}

.l-wrapper {
  @include wrapper;
}

.mobile-hidden {
  @include mediaQueries("ML", "max") {
    display: none !important;
  }
}
.desktop-hidden {
  @include mediaQueries("ML", "min") {
    display: none !important;
  }
}

.url {
  text-decoration: none;
  @include hover_underline;
}

@media (prefers-reduced-motion) {
  path {
    animation: none !important;
    stroke-dasharray: unset !important;
  }
}
