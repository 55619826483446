@font-face {
  font-family: "isonormregular";
  src: url("../assets/fonts/isonorm-d-ot-webfont.eot");
  src: url("../assets/fonts/isonorm-d-ot-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("../assets/fonts/isonorm-d-ot-webfont.woff2") format("woff2"),
    url("../assets/fonts/isonorm-d-ot-webfont.woff") format("woff"),
    url("../assets/fonts/isonorm-d-ot-webfont.ttf") format("truetype"),
    url("../assets/fonts/isonorm-d-ot-webfont.svg#isonormregular") format("svg");
  font-weight: normal;
  font-style: normal;
}
