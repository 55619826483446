.m-banners {
  .is--3rd-in &,
  .is--3rd-out & {
    display: none;
  }

  &__line {
    position: absolute;
    width: 100%;
    z-index: 100;
    opacity: 0;
    transition: top 1s 2.5s, height 0.1s 1s, opacity 0.3s;

    &--top {
      top: -300px;
      .is--1st-in & {
        top: 0;
        opacity: 1;
        @include mediaQueries("M", "min") {
          top: -20px;
        }
        // @include mediaQueries("ML", "min") {
        //   top: -58px;
        // }
        @include mediaQueries("L", "min") {
          top: -80px;
        }

        @include mediaQueries("XL", "min") {
          top: 0;
        }
      }

      .is--1st-out & {
        transition-delay: 0;
      }

      .swiper {
        padding-bottom: 50px;
      }
    }

    &--bottom {
      display: none;
      top: 100%;

      .is--1st-in & {
        @include mediaQueries("M", "min") {
          top: calc(100% - 230px);
          opacity: 1;
        }
        @include mediaQueries("L", "min") {
          top: calc(100% - 130px);
        }
        @include mediaQueries("XL", "min") {
          top: calc(100% - 230px);
        }
      }

      @include mediaQueries("M", "min") {
        display: block;

        .swiper {
          padding-top: 50px;
        }

        .is--1st-out & {
          top: 100%;
          .swiper {
            padding-top: 0;
            height: 0;
            transition: height 0.1s 1s;
          }
        }
      }
    }
  }

  .swiper-slide {
    width: auto;
    .c-poster {
      transition: transform 0.5s ease-out;
      @media (hover) {
        &:hover {
          transform: scale(1.3);
          z-index: 2;
        }
      }
    }
  }
}
