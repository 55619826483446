.c-radio {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: 10px;
  line-height: 1.2;
  text-transform: uppercase;

  @include mediaQueries("ML", "min") {
    font-size: 13px;
  }
  @include mediaQueries("XXL", "min") {
    font-size: 15px;
  }
}
